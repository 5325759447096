import { useState,useEffect } from "react";
import { ResultsContainer } from "./results.styles";
import axios from "axios";

const Results = (props) => {

    const { state,getTime,timeForEachBundle } = props
    const { 
        minutes, // Initial mins calculated for each bundle
        profileLength,
        speedInFPM
     } = state
    const [localState,setLocalState] = useState({
        currentTime:null,
        currentDate:null,
        numberOfParts:0,
        partsPerBundle:0,
        lineNumber:0,
        timeWhenOrderIsComplete:null
    })
    const { 
        currentTime,
        currentDate,
        numberOfParts,
        partsPerBundle,
        lineNumber,
        timeWhenOrderIsComplete
    } = localState

    const input = (prop,e) => {
        setLocalState({...localState,[prop]:e.target.value})
    }

    const getTimeOfDay = (hours,mins) => {
        // Increment hour if mins exceeds 60
        if (mins > 60) {
            hours += 1
            mins -= 60
        }
        
        // Increment daya if hours exceeds 24
        if (hours > 24) {
            hours -=24
        }

        const timeAndDay = `${hours}:${mins}`
        return timeAndDay
    }

    const run = (e) => {
        e.preventDefault()
        const totalNumberOfBundles = parseInt(numberOfParts)/parseInt(partsPerBundle)
        var totalTime = totalNumberOfBundles*minutes
        totalTime = getTime(totalTime).split(':')
        const hours = parseInt(currentTime[0])+parseInt(totalTime[0])
        const totalMins = parseInt(currentTime[1])+parseInt(totalTime[1])

        setLocalState({
            ...localState,
            totalNumberOfBundles:totalNumberOfBundles,
            timeWhenOrderIsComplete:getTimeOfDay(hours,totalMins)
        })
    }

    const writeToDB = async (e) => {
        e.preventDefault()
        const time_calculated = currentDate + ' @ ' + currentTime.join(':')
        const fpm = speedInFPM
        const length = profileLength
        const line_id = lineNumber
        const time_per_bundle = timeForEachBundle
        const order_completion_time = timeWhenOrderIsComplete

        await axios.post('/api/lines/new', {time_calculated,fpm,length,line_id,time_per_bundle,order_completion_time})
        .then(res => {
            alert('items added')
        })
        .catch(res => {
            alert('there seems to be and error')
        })
    }

    useEffect(() => {
        const executionTime = new Date() // Time this caculation was made
        const dateArray = executionTime.toString().split(' ')
        const currentTime = dateArray[4].split(':')
        const currentDate = dateArray.slice(0,4).join(' ')

        setLocalState({
            ...localState,
            currentTime:currentTime,
            currentDate:currentDate
        })
        
    },[])

    return (
        <ResultsContainer>

            <h4>
                Calculate line change
            </h4>

            <input
                placeholder="Parts for bundle"
                onChange={(e) => input('partsPerBundle',e)}
                type="number"
            />

            <input
                placeholder="Number of parts"
                type="number"
                onChange={(e) => input('numberOfParts',e)}
            />
            
            <button onClick={(e) => run(e)}>run</button>

            <h4>
                Save line parameters
            </h4>

            <input 
                placeholder="line number"
                onChange={(e) => input('lineNumber',e)}
            />

            {lineNumber && 
            <button
                onClick={(e) => writeToDB(e)}
            >
                save
            </button>}
        </ResultsContainer>
    )
}

export default Results