import OneDoc from "./OneDoc"
import '../../Table/Table.css'
import Loading from "../../Loading/Loading"
import { useState,useEffect } from "react"
import axios from "axios"

const Documents = (props) => {

    const [docs,setDocs] = useState([]) // -- All docs in DB
    const [isLoading,setIsLoading] = useState(false)
    const [create,setCreate] = useState(false) // -- Create new document
    const [selected,setSelected] = useState(null) // -- For displaying currently selected document
    const currentDoc = localStorage['currentDoc'] // -- For auto loading currently selected document
    const currentCategory = localStorage['currentCategory']
    const width = '50%'

    const [state,setState] = useState({
        viewCategories:false,
        search:''
    })

    const {
        viewCategories,
        search
    } = state

    useEffect(() => {
        grabDocs()
        if(localStorage['currentDoc'] === "undefined"){
            localStorage.setItem('currentDoc','null')
        }
        selectMemo(currentDoc)

        if(localStorage['currentCategory'] === "undefined"){
            localStorage.setItem('currentCategory','')
        }
        setState({
            ...state,
            search:currentCategory
        })

    },[])

// ------------ Local / Browser management ------------------ //
    const clearAndRefresh = (event) => { // -- Removes all document data in local storage.
        searchForCat('')
        closeDoc(event)
        grabDocs(true)
    }

    
    const searchForCat = (val) => { // -- Search docs by category
        var alpha = val.toLowerCase()
        setState({
            ...state,
            search:alpha
        })
        props.theWindow('currentCategory',alpha)
    }

    const closeDoc = (e) => { // -- Clear currently opened document
        e.preventDefault()
        selectMemo('null')
        setCreate(false)
    }

    const selectMemo = (id) => { // -- Select doc to open
        localStorage.setItem('currentDoc',id)
        setSelected(id)
    }

// -------------------------------------------- //

// ------------ DB managment ------------------ //
    // -- Create new document -- //
    const newDoc = (state) => {
        setIsLoading(true)
        axios.post('/api/memos/new',state).then(res => {
            selectMemo(res.data.memo_id)
            setCreate(false)
            setIsLoading(false)
            grabDocs()
        })
    }
    // -- Update document -- //
    const sendUpdate = async (state) => {
        await setIsLoading(true)
        axios.put('/api/memos/update', state).then((res) => {
            // const currentItemIndex = docs.findIndex(el => el.memo_id = res.data[0].memo_id)
            // const {title,category,body} = res.data[0]
            // docs[currentItemIndex] = {
            //     ...docs[currentItemIndex],
            //     title:title,
            //     category:category,
            //     body:body
            // }
            setIsLoading(false)
            grabDocs()
        })
    }

    
    const grabDocs = async () => { // -- Get all docs - Reset current doc if param === true
        await setIsLoading(true)
        await axios.get('/api/memos/get').then(res => {
            setDocs(res.data)
            setIsLoading(false)
        }).catch(err => console.log('error',err))
    }
    
    const deleteDoc = (state) => { // -- Delete document
        setIsLoading(true)
        axios.delete(`/api/memos/delete/${state.memo_id}`).then(() => {
            grabDocs(true)
            setIsLoading(false)
            selectMemo('null')
            setCreate(false)
        })
    }
// --------------------------------------------------- //

    // -- Find & Display Currently Selected Doc -- //
    const currentItem = docs.filter(docEl => docEl.memo_id === parseInt(selected)) 
    const mappedItem = currentItem.map(el => {
        return (
            <OneDoc
            key={el.memo_id}
            body={el.body}
            memo_id={el.memo_id}
            title={el.title}
            category={el.category}
            grabDocs={grabDocs}
            DB={sendUpdate}
            isLoading={isLoading}
            deleteDoc={deleteDoc}
            closeDoc={closeDoc}
            />
            )
    })

    // -- Search for doc by category (default value is empty string) -- //
    const searchedDocs = docs.filter(el => { return el.category.toLowerCase().includes(search) })
    // -- Only display category names and remove duplicates -- //
    const eachCategories = () => {
        let categoryArr = []
        searchedDocs.map((el,i) => {
            categoryArr.push(el.category)
        })
        let uniq = categoryArr.filter((element,index) => {
            return categoryArr.indexOf(element) === index
        })
        return uniq
    }

    const mappedCategories = eachCategories().map((el,i) => {
        return (
            <tr key={i} onClick={() => {
                setState({
                    ...state,
                    search:el.toLowerCase(),
                    viewCategories:!viewCategories
                })
                }
            }>
                <td style={{width:width}}>{el}</td>
            </tr>
        )
    })
    // -- Displays all docs in search list -- //
    const mappedDocList = searchedDocs.map((el,j) => {
        return (
        <tr key={j} memo_id={el.memo_id} category={el.category} onClick={() => selectMemo(el.memo_id)} style={{padding:'',width:'100%',backgroundColor:''}} >
            <td style={{width:width}} >{el.title}</td>
            <td style={{width:width}} >{el.category}</td>
        </tr>)
    })

    return(
        <div className="display-matrix">
            {isLoading ? <Loading/> : null}
            <section className="search-bar" >


                <a onClick={(e) => clearAndRefresh(e)}>reload all</a>

                <a onClick={() => setState({...state,viewCategories:!viewCategories})}>{viewCategories ? "categories" : 'all'}</a>

                <input value={search} placeholder="Search" onChange={(e) => searchForCat(e.target.value)} />

                <a onClick={() => searchForCat('')} >clear search?</a>

                <a onClick={() => props.handleForm('currentView','home')}>close doc</a>

            </section>
    
            <section>
                
                {!create ? (selected === 'null' ? 
                <table>
                    <thead>
                        <tr>
                            {!viewCategories && <th style={{width:width,backgroundColor:''}} >Title</th>}
                            <th style={{width:width,backgroundColor:''}} >{viewCategories ? 'categories' : 'category'}</th>
                            <th style={{width:'30px',backgroundColor:''}} onClick={() => setCreate(true)} ><i>new?</i></th>
                        </tr>
                    </thead>
                <tbody>{viewCategories ? mappedCategories : mappedDocList}</tbody>
                </table>
                
                 : mappedItem)
                 :
                <OneDoc
                    body={'text'}
                    memo_id={null}
                    title={'title'}
                    category={'category'}
                    selectMemo={selectMemo}
                    DB={newDoc}
                    grabDocs={grabDocs}
                    isLoading={isLoading}
                    closeDoc={closeDoc}
                    // setCreate={setCreate}
                    // close={close}
                    // updateList={updateList}
                    // docs={docs}
                    // setDocs={setDocs}
                />}

            </section>
        </div>
    )
}

export default Documents