import styled from "styled-components";

export const LineEditComponent = styled.form`
    position:relative;
    width:200px;
    min-height:200px;
    pointer-events:auto;

    div {
        display:flex;
        flex-direction:column;
    }

    button {
        margin-top:5px;
    }
`