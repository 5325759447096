import styled from "styled-components";

export const UploadPhoto = styled.section`
    position:relative;
    margin:auto;
    top:50px;
    min-width:250px;
    max-width:400px;
    height:400px;
    background-color:#fff;
`

export const PhotoSection = styled.section`
    width:90vw;
    min-height:500px;
`

export const ThumbnailDisplay = styled.div`
    min-height:100%;
    width:100%;
    display:flex;
    flex-direction:column;
`
    
    export const Thumbnail = styled.img`
    width:50px;
    height:50px;
`

export const SelectedImage = styled.img`
    position:absolute;
    width:298px;
    height:191px;
`