import React from "react";
import { SelectedImage, Thumbnail } from "./photos.styles";
import {deleteObject,getStorage,ref} from 'firebase/storage'
import { app } from "../../../base";
import { CloseIcon,DeleteIcon } from "../../global-styles/SVG";
const storage = getStorage(app)

const IMG_CLASSES = {
    selected:'selected',
    thumbnail:'thumbnail',
    null:null
}

const getImageClass = (imageClass = IMG_CLASSES.thumbnail) => 
    ({
        [IMG_CLASSES.selected]:SelectedImage,
        [IMG_CLASSES.thumbnail]:Thumbnail,
        [IMG_CLASSES.null]:null
    }[imageClass])

    
const BaseImage = ({url,imageClass,close,name,...otherProps}) => {

    const copy = () => {
        navigator.clipboard.writeText(url)
        alert('Url Copied')
    }

    
    const deletePhoto = () => {
        // --- Get ref --- //
        const storageRef = ref(storage, `${url}`)
        // --- Delete photo --- //
        try {deleteObject(storageRef).then(res => {
            alert('photo has been deleted')
            return
        })} catch (err) {
            alert('there was an error')
        }

    }

    const CustomImage = getImageClass(imageClass)

    return (
        <div {...otherProps}>
            <CloseIcon onClick={() => close(name,null)} />
            <DeleteIcon onClick={() => deletePhoto()} />
            <CustomImage src={url} onClick={copy} />
        </div>
    )

}

export default BaseImage