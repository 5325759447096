import { UploadPhoto } from "./photos.styles";
import { OverLay } from "../../global-styles/overlay";
import { getStorage,ref,uploadBytesResumable,getDownloadURL,listAll } from 'firebase/storage'
import { app } from '../../../base'
import Resizer from 'react-image-file-resizer'
import React, { useEffect, useState } from "react";
const storage = getStorage(app)
const defaultMinWidth = 298 * 4
const defaultMinHeight = defaultMinWidth % .6666
const defaultMaxWidth = 400 * 4
const defaultMaxHeight = defaultMaxWidth * .6666
// const defaultMaxHeight = 267
const path = `data/images` // Location of images on cloud

const NewPhoto = ({state,setState}) => {
    console.log('shows ratios',defaultMaxHeight)
    const [localState,setLocalState] = useState({
        preview:null,
        file:null,
        thumbnail:false,
        photo_name:'',
        preview:null,

        // Resize parameters //
        minWidth:defaultMinWidth,
        minHeight:defaultMinHeight,
        maxWidth:defaultMaxWidth,
        maxHeight:defaultMaxHeight,
        scale:1,
    })

    const hiddenFileInput = React.useRef(null);
    const {
        photo_name,
        preview,
        file,
        maxHeight,maxWidth,
        minWidth,minHeight,
        scale,
    } = localState

    const inputHandler = (e) => {
        e.preventDefault()
        const { name,value } = e.target
        setLocalState({
            ...localState,
            [name]:value
        })
    }

    const resize = async (e) => {
        var fileInput = false;
    
        if (e.target.files[0]) {
            fileInput = true
        }
    
        if (fileInput && scale >= 1) {
            try {
                Resizer.imageFileResizer(
                    e.target.files[0],
                    maxWidth*parseInt(scale),
                    maxHeight*parseInt(scale),
                    "JPEG",
                    50,
                    0,
                    (uri) => {imageToState(uri)},
                    
                    "file",
                    minWidth*parseInt(scale),
                    minHeight*parseInt(scale)
                );
            } catch (err) {
                console.log(err)
            }
        } else {imageToState(e.target.files[0])}
    }

    const imageToState = (uri) => {
        const objUrl = URL.createObjectURL(uri)
        setLocalState({
            ...localState,
            preview:objUrl,
            file:uri
        })
    }

    const clearPhoto = () => {
        setLocalState({
            ...localState,
            preview:null,
            file:null
        })
    }

    const addPhoto = async (e) => {
        
        // --- Get ref
        const storageRef = await ref(storage, `${path}/${photo_name}`)

        // --- Add to firebase
        if (photo_name.length > 1) {
            setState({...state,isLoading:true})
            try {
                await uploadBytesResumable(storageRef,file)
                await getDownloadURL(storageRef)
            } catch(error) {
                console.log(error)
            }
        
            await setState({
                ...state,
                isLoading:false,
                newPhoto:false
            })
        } else {
            return (
                alert('You need to name this photo')
            )
        }
    }
    
    
    return (
        <OverLay>
            <UploadPhoto>
                {!preview && <input type="file"
                accept=".png,.jpg"
                ref={hiddenFileInput}
                style={{
                    position:'absolute',
                    height:'50px',
                    width:'320px',
                }}
                onChange={e => resize(e)}
                />}

                {preview && 
                <>
                    <button onClick={clearPhoto}>cancel</button>
                    <img src={preview} style={{height:'191px',width:'298px'}} />
                    <button onClick={() => addPhoto()}>upload</button>
                </>}

                <button
                    onClick={() => setState({...state,newPhoto:false})}
                    style={{bottom:'0px',position:'absolute'}}
                >
                    close
                </button>
                <input
                    style={{right:'0px',bottom:'0px',position:'absolute'}} 
                    name="photo_name"
                    placeholder="name"
                    type="text"
                    onChange={(e) => inputHandler(e)} 
                />

                <input
                    style={{right:'0px',bottom:'25px',position:'absolute'}} 
                    name="scale"
                    placeholder="scale"
                    type="number"
                    onChange={(e) => inputHandler(e)} 
                />

            </UploadPhoto>
        </OverLay>
    )
}

export default NewPhoto