import { useEffect, useState } from "react"
import { LineEditComponent } from "./edit.styles"
import axios from "axios"

const EditLine = ({parameters,setEditLine,update}) => {

    const [localState,setLocalState] = useState({})

    useEffect(() => {setLocalState({...parameters})},[])

    function inputHandler(prop,val) {setLocalState({...localState,[prop]:val})}

    async function deleteFromDB(e) {
        e.preventDefault()
        await axios.delete(`./api/lines/delete/${parameters.line_id}`).then(res => {
            setEditLine(null)
            update()
        })
    }

    const mappedParameters = Object.entries(localState).map((el,i) => {
        return <div key={i}><label>{el[0]}</label><input type="text" value={el[1] ? el[1] : ''} onChange={(e) => inputHandler(el[0],e.target.value)}/></div>
    })

    return (
        <LineEditComponent>
            {mappedParameters}
            <button>update</button>
            <button onClick={(e) => deleteFromDB(e)}>delete</button>
            <button onClick={() => setEditLine(null)}>close</button>
        </LineEditComponent>
    )
}

export default EditLine