const LineRow = ({parameters,setEditLine}) => {
    const {
        line_id,
        fpm,
        time_calculated,
        length,
        time_per_bundle,
        order_completion_time
    } = parameters

    return (
        <tr onClick={() => setEditLine(parameters)}>
            <td>{line_id}</td>
            <td>{fpm}</td>
            <td>{time_calculated.split(' ').splice(1,2).join(' ')}</td>
            <td>{length}</td>
            <td>{time_per_bundle}</td>
            <td>{order_completion_time ? order_completion_time : "N/A"}</td>
        </tr>  
    )
}

export default LineRow