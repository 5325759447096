import Loading from "../../Loading/Loading"

import BaseImage from "./image.component"
import {
    ThumbnailDisplay,
    Thumbnail,
    PhotoSection,
    SelectedImage
} from "./photos.styles"

import { useState,useEffect } from "react"
import NewPhoto from "./upload.component"
import { getStorage,ref,listAll,list,getDownloadURL } from 'firebase/storage'
const storage = getStorage();

const Photos = (props) => {

    const listRef = ref(storage, `data/images`);
    
    const [state,setState] = useState({
        isLoading:false,
        newPhoto:false,
        theList:[],
        selectedImage:null
    })
    const {
        isLoading,
        newPhoto,
        theList,
        selectedImage
    } = state
    
    const { handleForm } = props

    
    useEffect(() => {
        (!theList[0] && getItems())
    },[])

    const getItems = async () => {
        setState({...state,isLoading:true})
        const listArr = []
        await listAll(listRef).then(res => {
                
            res.items.forEach(item =>{
                const itemRef = ref(storage, `data/images/${item.name}`)
                getDownloadURL(itemRef).then(res => {
                    listArr.push(res)
                })
            })
            setState({
                ...state,
                theList:listArr,
                isLoading:false
            })
        })
    }

    const stateManagement = (prop,val) => {
        setState({
            ...state,
            [prop]:val
        })
    }

    return(
        <PhotoSection>
            {isLoading ? <Loading/> : null}
            <section className="search-bar" >


                <a onClick={() => setState({...state,selectedImage:null})}>refresh</a>

                <a onClick={() => handleForm('currentView','home')}>close</a>

            </section>

            <section>
                {newPhoto &&
                <NewPhoto
                    state={state}
                    setState={setState}
                />
                }
                <table>
                    <thead>
                        <tr>
                            <th style={{width:'30px',backgroundColor:''}} onClick={() => setState({...state,newPhoto:true})} ><i>upload?</i></th>
                        </tr>
                    </thead>
                </table>
                <ThumbnailDisplay>
                        {!selectedImage ? theList.map((el,i) => {
                            return (
                                <Thumbnail
                                    key={i} src={el}
                                    onClick={() => setState({...state,selectedImage:el})}
                                />
                            )
                        }):

                        <BaseImage
                            imageClass={'selected'}
                            url={selectedImage}
                            close={stateManagement}
                            name={'selectedImage'}
                        />}
                </ThumbnailDisplay>
            </section>
        </PhotoSection>
    )
}

export default Photos