
import { initializeApp } from 'firebase/app';
import  firebase  from 'firebase/compat/app'
import 'firebase/compat/firestore';

import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth'

import { 
    getFirestore,
    doc,
    getDoc,
    setDoc,
    collection,
    writeBatch,
    query,
    getDocs
 } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyC1bhnb5safp8iPXmCgyXI7D4huk-4Oi_U",
  authDomain: "bcwb-879fa.firebaseapp.com",
  projectId: "bcwb-879fa",
  storageBucket: "bcwb-879fa.appspot.com",
  messagingSenderId: "383022670787",
  appId: "1:383022670787:web:dbc60295fcb6011ca95376"
};


export const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: "select_account"
});

export const auth = getAuth();

export const signInWithGooglePopup = () => signInWithPopup(auth,provider);

export const db = getFirestore();

// --- ADDING DATA TO FIREBASE DATABASE --- //
// Add items to new DB in firebase. Since I'm learning these tools, this method might not be used in production
export const addCollectionAndDocuments =  async (collectionKey, objectsToAdd) => {
  const collectionRef = collection(db, collectionKey);
  // console.log('objects to add',objectsToAdd,collectionKey)
  const batch = writeBatch(db);
  
  objectsToAdd.forEach(object => {
    // object.photo_name = object.photo_name.split('/').slice(0,2).join('/')
    // console.log(object.photo_name,'obj photo name')
    const docRef = doc(collectionRef, '/' + object.photo_id.toString());
    batch.set(docRef,object)
  })

  await batch.commit();
  console.log('done');
}

// --- GETTING PHOTOS FROM FIREBASE --- //
export const getPhotosAndDocs = async () => {
  const collectionRef = collection(db, 'photos');
  const q = query(collectionRef);

  const querySnapShot = await getDocs(q)
  const photoMap = querySnapShot.docs.reduce((acc,docSnapShot) => {
    const { photo_name, url } = docSnapShot.data();
    acc[photo_name] = url;
    return acc;
  }, {})

  return photoMap

}
// --------------------------------------//

export const createUserDocumentFromAuth = async (userAuth,additionalInformation) => {
    const userDocRef = doc(db, 'users', userAuth.uid)
    const userSnapshot = await getDoc(userDocRef)

    if(!userSnapshot.exists()) {
        const { displayName,email } = userAuth;
        const createdAt = new Date();
    
        try {
            await setDoc(userDocRef, {
                displayName,
                email,
                createdAt,
                adminUser:false,
                ...additionalInformation,
            });
        }   catch (err) {
            console.log('there was an error',err.message)
        };
    };

    return userDocRef

}

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const  createAuthUserWithEmailAndPassword = async (email,password) => {
  if(!email || !password)return
  
  return await  createUserWithEmailAndPassword(auth,email,password)

}

export const  signInAuthUserWithEmailAndPassword = async (email,password) => {
  if (!email || !password) return
  
  return await  signInWithEmailAndPassword(auth,email,password)

}

export const signOutUser = async () => await signOut(auth)

export const onAuthStateChangedListener = (callback) => {
  onAuthStateChanged(auth, callback )
}