import { useState } from "react";
import './Line.css'
import Results from "./Results/results.components";

const Line = (props) => {

    const [ state,setState ] = useState({
        speedInFPM:0, // in feet per min
        profileLength:0, // in inches
        rows:0,
        columns:0,
        extrudes:0, // extrudes one or two ?

        // For calculating time to next profile change
        pieces_per_package:null,
        number_of_packages_needed:null,

        // total number of minutes per package
        minutes:null,
    })
    const [time,setTime] = useState(0)
    const [ error,setError ] = useState(null)
    
    const execute = (e) => {
        e.preventDefault()
        const { speedInFPM,profileLength,rows,columns,extrudes } = state
        var totalLength = (rows * columns) * (profileLength / 12) 
        var totalMins = ( totalLength / speedInFPM ) / extrudes // -- Total length of all pieces in feet

        if(extrudes === 1 || extrudes === 2) {
            setTime(getTime(totalMins)) // Total time per order
        } else {setError('How many profiles is extruder running?')}

        setState((state) => ({
            ...state,
            extrudes:0,
            minutes:totalMins
        }))
    }

    const getTime = (mins) => {
        const hours = mins / 60
        var remainingMins = (mins % 60)
        var returnTime = Math.floor(hours) + ':' + (remainingMins < 10 ? '0' + Math.floor(remainingMins) : Math.floor(remainingMins))
        return returnTime
      }

    const input = (prop,event) => {
        event.persist();
        setState((state) => ({
            ...state,
            [prop]: parseFloat(event.target.value),
        }));
    };

    return(
        <section>
            <form className="line-form">

                {error &&
                <div style={{position:'absolute',backgroundColor:'grey',height:'80px'}} onClick={() => setError(null)}>
                    {error}
                </div>}

                <strong>
                    Time
                </strong>

                <strong style={{marginLeft:'60px'}} >
                    {time}
                </strong>

                <input 
                    placeholder="speed in FPM" 
                    onChange={(e) => input('speedInFPM',e)} 
                />

                <input 
                    placeholder="Length in Inches"
                    type="number"
                    onChange={(e) => input('profileLength',e)} 
                />

                <input 
                    placeholder="Rows" 
                    type="number" 
                    onChange={(e) => input('rows',e)} 
                />

                <input 
                    placeholder="Columns" 
                    type="number" 
                    onChange={(e) => input('columns',e)} 
                />

                <input 
                    placeholder="Pieces per extruder" 
                    type="number" 
                    onChange={(e) => input('extrudes',e)} 
                />

                <button className="btn line-btn" style={{top:'108px'}} onClick={(e) => execute(e)} >
                    <strong>Run</strong>
                </button>

                <button className="btn line-btn" onClick={(e) => props.selectView('currentView','home')} >
                    <strong>exit</strong>
                </button>
            </form>

            {time != 0 && 
                <Results
                    state={state}
                    getTime={getTime}
                    timeForEachBundle={time}
                />
            }
        </section>
    )
}

export default Line