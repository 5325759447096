var Cryptr = require('cryptr'),
cryptr = new Cryptr('dFhyIOh*oDlr34503948309235ASFASR&7@#$');

module.exports = {

    decrypt: (req) => {
            const doc = cryptr.decrypt(req)
            return(doc)
    },

    encrypt: (req) => {
        const doc = cryptr.encrypt(req)
        return(doc)
    },

    devisionAnalysis: (req) => {
        const key = 'replix_vozz'
        return(req === key ?  true : false)
    }

}