import styled from "styled-components";

const rowHeight = 15

const columnWidth = 30 // Default column width
const col1 = columnWidth

export const TableContainer = styled.table`

    th,tr,td {
        display:flex;
        margin:auto;
        margin-left:2px;
    }

    min-width: 80vw;
    
    thead {
        position: sticky;
        text-align: left;
        top:0;
        background-color: #297F87;
        white-space: nowrap;
        z-index: 4;
        width: 100%;
    }
        
    th {
        color: black;
        max-width:${columnWidth}px;

        &:hover {
            background-color: #8b1b1b;
            transition: all 500ms;
        }
    }
        

    tbody {
        width:100%;
    }

    td {
        display: inline-block;
        max-width:${columnWidth}px;
        font-weight: 600;
        text-decoration: none;
        font-size: 10px;
        text-align: left;
        overflow: hidden;
        margin-left: 2px;
    }

    tr {
            width: 100%;
            margin-left: 20px;
            white-space: nowrap;
            font-size: 10px;
            font-weight: 600;
            margin: 0px;
            height: 30px;
            overflow:hidden;

        &:hover {
            background-color: #297F87;
            color: #fff;
            transition: all 500ms;
        }
    }

    tr:nth-of-type(2n+1) {
        background-color: rgba(240, 240, 240, .5);
    }
`

{/* <table >

<thead>
    <tr>
        <th style={{width:specItemWidth}} >name</th>
        <th style={{width:specItemWidth}}>class</th>
        <th style={{width:specItemWidth}}>gender</th>
        <th style={{width:specItemWidth}}>siblings<br/>spouses</th>
        <th style={{width:specItemWidth}}>parents<br/>children</th>
        <th style={{width:specItemWidth}}>Fare</th>
        <th style={{width:specItemWidth}}>results</th>
    </tr>
</thead>
    {/* -- ORIGINAL -- */}
    {/* <div className="data-spec-list" >{mappedPassengers}</div> */}

{/* <tbody>{mappedPassengers}</tbody> */}

// </table> */}