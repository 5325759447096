import { useState,useEffect } from "react";
import axios from "axios";
import LineRow from "./row.component";
import { TableContainer } from "../../../Table/table.styles";
import EditLine from "./EditRow/edit.component";
import { OverLay } from "../../../../globalstyles";

const LineTable = () => {

    const [lines,setLines] = useState([])
    const [editLine,setEditLine] = useState('')

    function update() {
        axios.get('./api/lines/get/all').then(res => setLines(res.data  )).catch(() => alert('there is an error'))
    }

    useEffect(() => {update()},[])

    const mappedLines = lines.map((el,index) => {
        return <LineRow key={index} id={index} parameters={el} setEditLine={setEditLine}/>
    })

    return (
        <>
            {editLine &&
            <OverLay>
                <EditLine 
                    parameters={editLine}
                    setEditLine={setEditLine}
                    update={update}
                />
            </OverLay>}

            <TableContainer>
                <tbody>
                    <tr>
                        <th>line</th>
                        <th>speed</th>
                        <th>date</th>
                        <th>length</th>
                        <th>bundle tims</th>
                        <th>order completion</th>
                    </tr>

                    {mappedLines}

                </tbody>
            </TableContainer>
        </>
    )
}

export default LineTable